import React, { useState, useEffect } from 'react';
import { Flex, Box } from 'reflexbox';
import { toast } from 'react-toastify';
import Api from '../../api';
import {
  CrossIcon,
  EditableTableData,
  ExportIcon,
  Form,
  HovereableFlex,
  ImportIcon,
  Paginator,
  RefreshIcon,
  RegularText,
  Select,
  SpinnerIcon,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableItemSkeleton,
  TableRow,
  Tooltip,
  UnregularButton,
  TrashCanIcon,
  Checkbox,
  CHECKBOX_STATUS
} from 'boss-ui';
import CreateResource from './CreateResource';
import ResourceAllocationFooter from './ResourceAllocationFooter';
import ResourceImportModal from './ResourceImportModal';
import ResourceAllocationPlanModal from './ResourceAllocationPlanModal';
import { RunPlanIcon, SplunkSearchIcon } from '../icons';
import { onError, getMessageError } from '../../libs/error-lib';
import { exportFile, parseCSV } from '../../libs/csv-lib';
import { getResourceCreationBody, getResourceUpdateBody } from '../../libs/resource-lib';
import {
  CSV_IMPORT_TEMPLATE,
  exportResourcesFile,
  generateSearchServerUrlBySpec,
  validateResourcesImportFileFields,
  removeDuplicateServerEntities
} from './utils';
import { useDynamoPaginator } from '../../libs/hooks-lib';
import { DEFAULT_PAGE_STEP, RESOURCE_TYPE, RESOURCE_URL_REGEXP } from '../../libs/constants';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const EVENT_WITHOUT_SPECS_MSG = 'Event does not have resources requirements';
const ALLOCATION_RUNNING_MESSAGE =
  'The allocation plan has been started, it will take several seconds until the process is concluded...';
const DEALLOCATION_RUNNING_MESSAGE =
  'The deallocation plan has been started, it will take several seconds until the process is concluded...';
const DEALLOCATE_AND_ADD = 'The allocation plan has alread ran, In order to add new resources please deallocate and then upload';
const ALLOCATION_REFETCH_TIMEOUT = 10000;
const SPEC_REFETCH_TIMEOUT = 10000;
const DEFAULT_PAGE_SIZE = 9;
const EXPORT_PAGE_SIZE = 100;
const API = new Api();

const prepareItemForTable = (item) => {
  const { spec, url, username, password } = item;
  let itemData;
  let itemRegExp;
  if (spec && spec.type) {
    itemData =
      spec.type.toLowerCase() === RESOURCE_TYPE.CREDENTIALS ? { username, password } : { url };
    itemRegExp = spec.type.toLowerCase() !== RESOURCE_TYPE.CREDENTIALS && RESOURCE_URL_REGEXP;
  }
  return { itemData, itemRegExp };
};

const getResources = async (specSrnId, lastEvaluatedKey = null, pageSize = DEFAULT_PAGE_SIZE) => {
  return API.get('events', `/specs/${specSrnId}/resources`, {
    queryStringParameters: {
      pageSize: pageSize,
      ...(lastEvaluatedKey && {
        ...lastEvaluatedKey,
      }),
    },
  });
};

let allocationPlanRefetchTimeOutId;
let specIntervalId;

export default function Resources({ resourcesSpec = [], loadingResourceSpecs = true, shouldUseAllocationV2 = false }) {
  const [loadingResources, setLoadingResources] = useState(false);
  const [resources, setResources] = useState([]);
  const [currentSpecPlanData, setCurrentSpecPlanData] = useState({});
  const [creatingResource, setCreatingResource] = useState(false);
  const [processingBulkResources, setProcessingBulkResources] = useState(false);
  const [resourceCreationSpec, setResourceCreationSpec] = useState(null);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [exportingResources, setExportingResources] = useState(false);
  const [loadingAllocationPlan, setLoadingAllocationPlan] = useState(false);
  const [allocationPlanVisible, setAllocationPlanVisible] = useState(false);
  const [calculateAllocationPlanVisible, setCalculateAllocationPlanVisible] = useState(false);
  const [deleteSelectionModalVisible, setDeleteSelectionModalVisible] = useState(false);
  const [deleteAllModalVisible, setDeleteAllModalVisible] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [{ pages, currentPage }, paginate, getLastEvaluatedKeyByPage] = useDynamoPaginator();
  const specOptions = resourcesSpec.map((r) => ({ ...r, value: r.srn, label: r.title }));
  const [selectedSpec, setSelectedSpec] = useState(specOptions[0]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [areResourcesSelected, setAreResourcesSelected] = useState(false);
  const [selectAllResourcesCheckboxStatus, setSelectAllResourcesCheckboxStatus] = useState(CHECKBOX_STATUS.CHECKED);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [isHardResetEnabled, setIsHardResetEnabled] = useState(false);
  const getResourcesBySpecId = async (specSrnId, page = 0) => {
    setLoadingResources(true);
    const lastEvaluatedKey = getLastEvaluatedKeyByPage(page);
    try {
      const resourcesRequest = await getResources(specSrnId, lastEvaluatedKey);
      setResources(resourcesRequest.resources.map((r) => ({ ...r, key: r.srn, selected: false })));
      paginate(resourcesRequest.pagination, page);
      setLoadingResources(false);
    } catch (e) {
      onError(e);
    }
  };

  let resourcesCsv = [];

  const onExportResources = async (specSrnId, lastEvaluatedKey = null) => {
    setExportingResources(true);
    setLoadingResources(true);
    try {
      const resourcesRequest = await getResources(specSrnId, lastEvaluatedKey, EXPORT_PAGE_SIZE);
      const tmpRes = resourcesRequest.resources.map((r) => ({ ...r, key: r.srn }));
      resourcesCsv.push(...tmpRes);
      if (resourcesRequest.pagination && !resourcesRequest.pagination.more) {
        exportResourcesFile(resourcesCsv, selectedSpec.title);
        setExportingResources(false);
        setLoadingResources(false);
        return;
      }
      onExportResources(specSrnId, resourcesRequest.pagination.LastEvaluatedKey);
    } catch (e) {
      onError(e);
      setExportingResources(false);
      setLoadingResources(false);
    }
  };

  const onExportResourcesCsv = () => {
    resourcesCsv = [];
    onExportResources(selectedSpec.srn);
  }

  const onSpecOptionChange = (option) => {
    setSelectedSpec(option);
    setSelectedResources([]);
  }

  const onDeleteSelectedClick = () => {
    if (!loading) {
      const message = `Delete
      ${selectedResources.length === resources.length
          ? ' current page resources'
          : ` selected resources (${selectedResources.length})`}?`;
      setDeleteModalMessage(message);

      setDeleteSelectionModalVisible(true);
    }
  }

  const deleteSelectedResources = async () => {
    if (!loading && !runningAllocationPlan) {
      setLoadingDelete(true);

      try {
        const promises = selectedResources.map(srn => API.del('resources', `/resources/${srn}`));
        await Promise.all(promises);
        setDeleteSelectionModalVisible(false);
        setSelectedResources([]);
        await refreshCurrentTab();
      } catch (e) {
        onError(e);
      } finally {
        setLoadingDelete(false);
      }
    }
  }

  const onDeleteAllClick = () => {
    if (!loading) {
      const message = `Delete ALL spec resources (${currentSpecPlanData.countResources})?`;
      setDeleteModalMessage(message);

      setDeleteAllModalVisible(true);
    }
  }

  const deleteAllResources = async () => {
    if (!loading && !runningAllocationPlan) {
      setLoadingDelete(true);

      try {
        await API.del('events', `/specs/${selectedSpec.srn}/resources`);
        setSelectedResources([]);
        setDeleteAllModalVisible(false);
        await refreshCurrentTab();
      } catch (e) {
        onError(e);
      } finally {
        setLoadingDelete(false);
      }
    }
  }

  const onNextClick = () => {
    getResourcesBySpecId(selectedSpec.srn, currentPage + DEFAULT_PAGE_STEP);
    setSelectedResources([]);
  }

  const onPrevClick = () => {
    getResourcesBySpecId(selectedSpec.srn, currentPage - DEFAULT_PAGE_STEP);
    setSelectedResources([]);
  }

  const checkAndEnableHardResetOption = (spec) => {
    const shouldEnable = spec && spec.planRequestedAt && !spec.planRunAt && new Date(spec.planRequestedAt) < new Date().getTime() - 1800000;
    setIsHardResetEnabled(shouldEnable);
  }


  const getSpecPlanData = async (specSrnId) => {
    try {
      const planDataRq = await API.get('events', `/specs/${specSrnId}`);
      setCurrentSpecPlanData({
        ...planDataRq.spec,
        ...planDataRq.spec.planStats,
      });
      checkAndEnableHardResetOption(planDataRq.spec);
      if (specIntervalId && planDataRq.spec && !planDataRq.spec.importStartedAt) {
        setProcessingBulkResources(false);
        clearInterval(specIntervalId);
      }
    } catch (e) {
      onError(e);
    }
  };

  const refreshCurrentTab = (targetPage = currentPage) => {
    if (selectedSpec && selectedSpec.srn) {
      return Promise.all([
        getResourcesBySpecId(selectedSpec.srn, targetPage),
        getSpecPlanData(selectedSpec.srn),
      ]);
    }
    return null;
  };

  useEffect(() => {
    setSelectedSpec(specOptions[0]);
    return () => API.abortCurrentRequest();
  }, [resourcesSpec]);

  useEffect(() => {
    refreshCurrentTab(0);
    return () => {
      clearTimeout(allocationPlanRefetchTimeOutId);
      clearInterval(specIntervalId);
    };
  }, [selectedSpec]);

  useEffect(() => {
    if (!loadingResourceSpecs && !resourcesSpec.length) {
      toast(EVENT_WITHOUT_SPECS_MSG);
    }
  }, [loadingResourceSpecs, resourcesSpec]);

  useEffect(() => {
    const allResourcesSelected = resources.length && selectedResources.length === resources.length;

    if (allResourcesSelected) {
      setAreResourcesSelected(true);
      setSelectAllResourcesCheckboxStatus(CHECKBOX_STATUS.CHECKED);
      return;
    }

    if (selectedResources.length > 0) {
      setAreResourcesSelected(true);
      setSelectAllResourcesCheckboxStatus(CHECKBOX_STATUS.MIXED);
      return;
    }

    setAreResourcesSelected(false);
  }, [selectedResources]);

  const onTableAddItem = () => {
    if (disableUpload) {
      toast(DEALLOCATE_AND_ADD);
      return;
    }
    const { type, srn } = selectedSpec;
    if (!type || !srn) {
      onError('Resource spec properties type or srn are missing');
      return;
    }
    setResourceCreationSpec({ type, srn });
  };

  const onCreateResource = async (resourceForm) => {
    setCreatingResource(true);
    try {
      await API.post('events', `/specs/${resourceCreationSpec.srn}/resources`, {
        body: [getResourceCreationBody(resourceForm, resourceCreationSpec.type)],
      });
      await refreshCurrentTab();
    } catch (e) {
      onError(e);
    } finally {
      setResourceCreationSpec(null);
      setCreatingResource(false);
    }
  };

  const onCreateResourcesBulk = async (parsedResult = [], specSrn) => {
    const formatedBulk = parsedResult.map((resource) =>
      getResourceCreationBody(resource, selectedSpec.type)
    );
    const bulkCreatePromises = [
      API.post('events', `/specs/${specSrn}/resources`, {
        body: formatedBulk,
      }),
    ];
    setCreatingResource(true);
    setProcessingBulkResources(false);
    try {
      await Promise.all(bulkCreatePromises);
      await refreshCurrentTab(0);
    } catch (err) {
      const msgErr = getMessageError(err);
      if (msgErr && msgErr.indexOf('timed out') > -1) {
        onError('The upload will take more seconds to be processed');
        setProcessingBulkResources(true);
        specIntervalId = setInterval(() => refreshCurrentTab(0), SPEC_REFETCH_TIMEOUT);
      } else {
        onError(err);
      }
    } finally {
      setCreatingResource(false);
    }
  };

  const onEditResource = async (resource, change) => {
    try {
      await API.patch('events', `/resources/${resource.srn}`, {
        body: getResourceUpdateBody(change, resource.spec.type),
      });
    } catch (e) {
      onError(e);
    } finally {
      await refreshCurrentTab();
    }
  };

  const onDeleteResource = async (resource) => {
    try {
      await API.del('resources', `/resources/${resource.srn}`);
      const filteredResources = resources.filter((r) => r.srn !== resource.srn);
      setResources(filteredResources);
      setSelectedResources([]);
      await refreshCurrentTab(0);
    } catch (e) {
      onError(e);
    }
  };

  const onSelectionChange = (resource) => {
    let selected = [...selectedResources];

    if (selected.some(r => r === resource.srn)) {
      selected = selected.filter(s => s != resource.srn);
    } else {
      selected.push(resource.srn);
    }

    setSelectedResources(selected);
  }

  const processFile = async (file) => {
    try {
      const parsedResult = await parseCSV({
        file,
      });
      const uniqueServerEntities = removeDuplicateServerEntities(parsedResult);
      validateResourcesImportFileFields(uniqueServerEntities, selectedSpec.type);
      await onCreateResourcesBulk(uniqueServerEntities, selectedSpec.srn);
    } catch (parseError) {
      onError(parseError);
    } finally {
      setImportModalVisible(false);
    }
  };


  const onDownloadCSVImportTemplate = () => {
    exportFile(CSV_IMPORT_TEMPLATE, 'resourcesImportation');
  };

  const calculateAllocationPlan = async (specSrnId) => {
    setLoadingAllocationPlan(true);
    try {
      const rq = await API.get('events', `/specs/${specSrnId}/plan`);
      setCurrentSpecPlanData((prevSpecPlanData) => ({
        ...prevSpecPlanData,
        ...rq,
      }));
    } catch (e) {
      onError(e);
    } finally {
      setLoadingAllocationPlan(false);
    }
  };

  const getAllocationPlan = async (specSrnId) => {
    setLoadingAllocationPlan(true);
    let planData = {};
    try {
      const rq = await API.get('events', `/specs/${specSrnId}/plan`);
      setCurrentSpecPlanData((prevSpecPlanData) => ({
        ...prevSpecPlanData,
        ...rq,
      }));
      planData = rq;
    } catch (e) {
      onError(e);
    } finally {
      setLoadingAllocationPlan(false);
    }
    return planData;
  };

  const executeAllocationPlan = async (specSrnId) => {
    setLoadingAllocationPlan(true);
    try {
      await API.post('events', `/specs/${specSrnId}/plan`);
      refreshCurrentTab();
      toast(ALLOCATION_RUNNING_MESSAGE);
      allocationPlanRefetchTimeOutId = setTimeout(
        () => refreshCurrentTab(),
        ALLOCATION_REFETCH_TIMEOUT
      );
    } catch (e) {
      onError(e);
    } finally {
      setLoadingAllocationPlan(false);
      setAllocationPlanVisible(false);
    }
  };

  const resetAllocationPlan = async (specSrnId) => {
    setLoadingAllocationPlan(true);
    try {
      await API.del('events', `/specs/${specSrnId}/plan`, { queryStringParameters: { isHardReset: isHardResetEnabled } });
      refreshCurrentTab();
      toast(DEALLOCATION_RUNNING_MESSAGE);
      allocationPlanRefetchTimeOutId = setTimeout(
        () => refreshCurrentTab(),
        ALLOCATION_REFETCH_TIMEOUT
      );
    } catch (e) {
      onError(e);
    } finally {
      setLoadingAllocationPlan(false);
      setAllocationPlanVisible(false);
    }
  };

  const onSelectAllResourcesChange = () => {
    if (!areResourcesSelected) {
      setSelectedResources(resources.map(resource => resource.srn));
    } else {
      setSelectedResources([]);
    }
  }

  const alreadyRanAllocationPlan = currentSpecPlanData && currentSpecPlanData.planRunAt;
  const runningAllocationPlan = currentSpecPlanData && currentSpecPlanData.planRequestedAt;
  const loading = loadingResourceSpecs || loadingResources;
  const disableUpload = shouldUseAllocationV2 && alreadyRanAllocationPlan && currentSpecPlanData.type === 'credentials';

  return (
    <>
      <Form noBorder>
        <Flex p="40px" pb="30px" width="924px" height="700px" flexDirection="column">
          <Flex width={1}>
            <Box width={1 / 2}>
              <Select options={specOptions} value={selectedSpec} onChange={onSpecOptionChange} />
            </Box>
            {(!processingBulkResources && !exportingResources) && (
              <Flex ml="auto" alignItems="center">
                <HovereableFlex
                  mr="18px"
                  data-tip
                  data-for="import"
                  disable={disableUpload}
                  onClick={() => {
                    if (!disableUpload && resourcesSpec.length) {
                      setImportModalVisible(true);
                    }
                  }}
                >
                  <ImportIcon size="24px" hoverColor="#66bb6a" />
                  <Tooltip id="import" borderColor="#66bb6a">
                    {disableUpload ? DEALLOCATE_AND_ADD : 'Import resources CSV'}
                  </Tooltip>
                </HovereableFlex>
                <HovereableFlex
                  data-tip
                  data-for="export"
                  onClick={onExportResourcesCsv}
                >
                  <ExportIcon size="24px" hoverColor="#66bb6a" />
                  <Tooltip id="export" borderColor="#66bb6a">
                    Export resources CSV
                  </Tooltip>
                </HovereableFlex>
              </Flex>
            )}
          </Flex>
          <Flex mt="20px" alignItems="center">
            <HovereableFlex data-tip data-for="refresh" onClick={() => refreshCurrentTab()} mr="12px">
              <RefreshIcon rotate={loading} color="#898989" hoverColor="#d0d0d0" />
              <Tooltip id="refresh">Refresh list</Tooltip>
            </HovereableFlex>
            {alreadyRanAllocationPlan ? (
              <HovereableFlex
                data-tip
                data-for="deallocate"
                onClick={() => setAllocationPlanVisible(true)}
                mr="12px"
              >
                <CrossIcon size="22px" />
                <Tooltip id="deallocate" borderColor="#e06c75">
                  Deallocate resources
                </Tooltip>
              </HovereableFlex>
            ) : (
              <HovereableFlex
                data-tip
                data-for="run-allocation"
                onClick={() => setAllocationPlanVisible(true)}
                mr="12px"
              >
                <RunPlanIcon size="18px" />
                <Tooltip id="run-allocation" borderColor="#66bb6a">
                  Run allocation
                </Tooltip>
              </HovereableFlex>
            )}
            <Flex
              onClick={() =>
                window.open(generateSearchServerUrlBySpec(currentSpecPlanData.srn), '_blank')
              }
            >
              <SplunkSearchIcon data-tip data-for="splunk-search" size="16px" />
              <Tooltip id="splunk-search">Go to search server for spec traces.</Tooltip>
            </Flex>
            <Flex ml="12px">
              {
                isHardResetEnabled &&
                <HovereableFlex
                  data-tip
                  data-for="hard-reset"
                  onClick={() => setAllocationPlanVisible(true)}
                  mr="12px"
                >
                  <CrossIcon size="22px" />
                  <Tooltip id="deallocate" borderColor="#e06c75">
                    Deallocate resources
                  </Tooltip>
                </HovereableFlex>
              }
            </Flex>
            {runningAllocationPlan && (
              <Flex align="center" ml="auto">
                <SpinnerIcon size="18px" />
                <Flex>
                  <RegularText fontSize="12px">{alreadyRanAllocationPlan ? 'Running deallocation' : 'Running allocation'}</RegularText>
                </Flex>
              </Flex>
            )}
            {processingBulkResources && (
              <Flex align="center" ml="auto">
                <SpinnerIcon size="18px" />
                <Flex>
                  <RegularText fontSize="12px">Importing CSV</RegularText>
                </Flex>
              </Flex>
            )}
            {exportingResources && (
              <Flex align="center" ml="auto">
                <SpinnerIcon size="18px" />
                <Flex>
                  <RegularText fontSize="12px">Exporting CSV</RegularText>
                </Flex>
              </Flex>
            )}
          </Flex>
          <Flex mt="10px" flexDirection="column">
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader style={{ paddingLeft: '10px' }}>
                    {resources.length > 0 && !loading &&
                      <label>
                        <Checkbox
                          checked={areResourcesSelected}
                          onChange={onSelectAllResourcesChange}
                          status={selectAllResourcesCheckboxStatus} />
                      </label>
                    }
                  </ TableHeader>
                  <TableHeader style={{ paddingLeft: '10px' }}>
                    {areResourcesSelected &&
                      <Flex alignItems={'center'}>
                        <HovereableFlex
                          data-tip
                          data-for="deleteSelected"
                          onClick={onDeleteSelectedClick}>
                          <TrashCanIcon size="22px" />
                          <Tooltip id="deleteSelected" borderColor="#e06c75">{`Delete selected (${selectedResources.length})`}</Tooltip>
                        </HovereableFlex>
                        {selectedResources.length === resources.length &&
                          <>
                            <UnregularButton
                              data-tip
                              data-for="deleteAll"
                              active
                              onClick={onDeleteAllClick} style={{ marginLeft: "10px", color: "#e06c75" }}>
                              {`Delete ALL (${currentSpecPlanData.countResources})`}
                            </UnregularButton>
                            <Tooltip id="deleteAll" borderColor="#e06c75">Delete ALL resources from spec</Tooltip>
                          </>
                        }
                      </Flex>
                    }
                  </ TableHeader>
                  <TableHeader justify="flex-end" width="10%">
                    <UnregularButton active onClick={() => !loading && onTableAddItem()}>
                      +
                    </UnregularButton>
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <>
                    <TableItemSkeleton numberOfCells={3} />
                  </>
                ) : resources.length ? (
                  resources.map((r) => {
                    const { itemData, itemRegExp } = prepareItemForTable(r);
                    const isChecked = selectedResources.includes(r.key);
                    return (
                      <EditableTableData
                        key={r.key}
                        itemData={itemData}
                        itemRegExp={itemRegExp}
                        isChecked={isChecked}
                        onItemChanged={(change) => onEditResource(r, change)}
                        onItemDelete={() => onDeleteResource(r)}
                        onSelectionChange={() => onSelectionChange(r)}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableData width="10%" center colspan={3}>
                      No items found
                    </TableData>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <ResourceAllocationFooter
              optimalCount={currentSpecPlanData.optimal}
              averageUsage={currentSpecPlanData.averageUsage}
              createdCount={currentSpecPlanData.countResources}
              lastPlanRunAt={currentSpecPlanData.planRunAt}
              isFungible={currentSpecPlanData.isFungible}
              loading={loadingAllocationPlan}
              dirty={currentSpecPlanData.dirty}
            />
          </Flex>
          <Flex width={1} justifySelf="flex-end" justifyContent="center" mt="auto">
            <Paginator
              isNext={pages[currentPage] && pages[currentPage].nextPagePointer}
              onNext={onNextClick}
              isPrev={currentPage > 0}
              onPrev={onPrevClick}
              paginating={loading}
            />
          </Flex>
        </Flex>
      </Form>
      {resourceCreationSpec && (
        <CreateResource
          type={resourceCreationSpec.type}
          onAccept={onCreateResource}
          onCancel={() => setResourceCreationSpec(null)}
          loading={creatingResource}
        />
      )}
      {importModalVisible && (
        <ResourceImportModal
          onImport={processFile}
          onCancel={() => setImportModalVisible(false)}
          onDownloadTemplate={onDownloadCSVImportTemplate}
          loading={creatingResource}
        />
      )}
      {calculateAllocationPlanVisible && (
        <ResourceAllocationPlanModal
          onCalculate={() => calculateAllocationPlan(selectedSpec.srn)}
          planData={currentSpecPlanData}
          onCancel={() => setCalculateAllocationPlanVisible(false)}
          loading={loadingAllocationPlan}
        />
      )}
      {allocationPlanVisible && (
        <ResourceAllocationPlanModal
          title={selectedSpec.title}
          isFungible={selectedSpec.isFungible}
          loading={loadingAllocationPlan}
          currentCount={currentSpecPlanData.countResources}
          hasRun={currentSpecPlanData.planRunAt}
          capacity={currentSpecPlanData.participantCapacity}
          getPlanData={() => getAllocationPlan(selectedSpec.srn)}
          onRunPlan={() => executeAllocationPlan(selectedSpec.srn)}
          onDeallocatePlan={() => resetAllocationPlan(selectedSpec.srn)}
          onCancel={() => setAllocationPlanVisible(false)}
          isHardResetEnabled={isHardResetEnabled}
        />
      )}
      {deleteSelectionModalVisible && (
        <DeleteConfirmationModal
          message={deleteModalMessage}
          loading={loadingDelete}
          onAccept={deleteSelectedResources}
          onCancel={() => setDeleteSelectionModalVisible(false)}
        />
      )}
      {deleteAllModalVisible && (
        <DeleteConfirmationModal
          message={deleteModalMessage}
          loading={loadingDelete}
          onAccept={deleteAllResources}
          onCancel={() => setDeleteAllModalVisible(false)}
        />
      )}
    </>
  );
}
