import styled from 'styled-components';
import { Form, TrashCanIcon } from 'boss-ui';
import { Box } from 'reflexbox';
import React, { useEffect, useState } from 'react';
import { Heading3, Heading4 } from './headings';
import { HorizontalLayout } from './HorizontalLayout';
import { StyledButton } from './StyledButton';

const StyledForm = styled(Form)`
    padding: 20px;
    border: none;

    & section:not(:first-child) {
        margin-top: 30px;
    }
`;

const EventsDropdown = styled.select`
    flex-grow: 1;
    min-width: 200px;
    height: 30px;
`;
export const ResourceAllocationFeatureFlagForm = ({
    eventsToChooseFrom,
    globallyEnabled, setGloballyEnabled,
    enabledEvents, addEvent, removeEvent,
    submitForm, savingChanges
}) => {
    return (
        <StyledForm>
            <section>
                <header>
                    <Heading3>About</Heading3>
                </header>
                <p>This feature flag configures use of version 2 of Resource Allocation logic</p>
            </section>
            <section>
                <header>
                    <Heading3>Enable Globally?</Heading3>
                </header>
                <Box>
                    <input type="checkbox" checked={globallyEnabled} onChange={(e) => {
                        setGloballyEnabled(e.target.checked);
                    }} />
                    {globallyEnabled ? 'Enabled for all events' : 'Only enabled for events specified below'}
                </Box>
            </section>
            <section>
                <header>
                    <Heading3>Enabled for Specific Events</Heading3>
                </header>
                {
                    enabledEvents.map((event) => (
                        <EventRecord key={event.eventId} event={event} removeEvent={removeEvent} />
                    ))
                }
                {eventsToChooseFrom.length > 0 && <>
                    <Heading4>Add another event to whitelist (current & future events)</Heading4>
                    <AddRecordContainer events={eventsToChooseFrom} addEvent={addEvent} />
                </>
                }
            </section>
            <section>
                <StyledButton disabled={savingChanges} type="button" onClick={submitForm}>
                    {savingChanges ? 'Saving Changes...' : 'Save Changes'}
                </StyledButton>
            </section>
        </StyledForm>
    );
};

function EventRecord({ event, removeEvent, ...props }) {
    return <HorizontalLayout {...props}>
        <p>{event.name} ({event.eventId})</p>
        <RemoveButton onClick={() => removeEvent(event.eventId)}>Remove</RemoveButton>
    </HorizontalLayout>;
}

function AddRecordContainer({ events, addEvent }) {
    const [selectedEvent, setSelectedEvent] = useState(undefined);
    const selectOnChange = (e) => {
        setSelectedEvent(e.target.value);
    };
    const buttonOnClick = () => {
        addEvent(selectedEvent);
    };

    useEffect(() => {
        const firstEvent = events.length > 0 ? events[0].eventId : undefined;
        setSelectedEvent(firstEvent);
    }, [events]);

    return <HorizontalLayout>
        {events.length > 0 && <>
            <EventsDropdown value={selectedEvent} onChange={selectOnChange}>
                {events.map((event) => <option key={event.eventId}
                    value={event.eventId}>{event.name} ({event.eventId})</option>)}
            </EventsDropdown>
            <StyledButton type="button" onClick={buttonOnClick}>Add Event</StyledButton>
        </>}
    </HorizontalLayout>;

}

function RemoveButton({ ...props }) {
    return <StyledButton type="button" {...props}><TrashCanIcon /></StyledButton>;
}