import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Flex } from 'reflexbox';
import {
  Modal,
  RegularText,
  Button,
  Table,
  TableBody,
  TableData,
  TableRow,
  TableHead,
  TableHeader,
} from 'boss-ui';

const Background = styled(Flex)`
  background-color: ${(props) => props.theme.color.form.background};
  padding-top: ${(props) => props.theme.margin.m};
  padding-bottom: ${(props) => props.theme.margin.m};
  padding-left: ${(props) => props.theme.margin.s};
  padding-right: ${(props) => props.theme.margin.s};
`;

const LoadingPoint = <Skeleton circle width="15px" height="15px" />;

export default function ResourceAllocationPlanModal({
  getPlanData,
  onRunPlan,
  onDeallocatePlan,
  onCancel,
  title = '',
  currentCount = 0,
  capacity,
  isFungible = false,
  loading = false,
  hasRun = false,
  isHardResetEnabled = false,
}) {
  const enableDeallocation = hasRun || isHardResetEnabled;
  const [planData, setPlanData] = useState({});
  const theme = useTheme();
  const SKELETON_STYLE = {
    COLOR: useTheme().color.skeleton.color,
    EFFECT: useTheme().color.skeleton.highlightColor,
  };
  useEffect(() => {
    const fetchPlanData = async () => {
      setPlanData(await getPlanData());
    };
    fetchPlanData();
  }, []);

  const getCurrentColor = (actual, minimum, optimal, alreadyRun) => {
    const actualNumber = Number(actual);
    const minimumNumber = Number(minimum);
    const optimalNumber = Number(optimal);
    if (alreadyRun) {
      if (actualNumber >= optimalNumber + actual) {
        return theme.color.allocationMonitor.optimal;
      }
    } else if (actualNumber >= optimalNumber) {
      return theme.color.allocationMonitor.optimal;
    }
    if (actualNumber < minimumNumber) {
      return theme.color.allocationMonitor.danger;
    }
    return theme.color.allocationMonitor.warning;
  };

  const onRunClick = () => {
    if (loading) {
      return;
    }
    if (enableDeallocation) {
      onDeallocatePlan(isHardResetEnabled);
      return;
    }
    onRunPlan();
  };

  return (
    <Modal style={{ overflow: 'hidden' }} active onBackgroundClick={onCancel}>
      <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
        <Background flexDirection="column" width="500px">
          <RegularText mediumWeight fontSize="22px">
            {title}
          </RegularText>
          <Flex my="10px" />
          {isFungible ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader colspan={2}>Allocation details</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableData>Users waiting for resources</TableData>
                  <TableData>{loading ? LoadingPoint : planData.consumers}</TableData>
                </TableRow>
                <TableRow>
                  <TableData>Capacity per Instance</TableData>
                  <TableData>{loading ? LoadingPoint : capacity}</TableData>
                </TableRow>
                <TableRow>
                  <TableData>Lower bound</TableData>
                  <TableData>{loading ? LoadingPoint : planData.lowerBound}</TableData>
                </TableRow>
                {hasRun ? (
                  <TableRow>
                    <TableData>Instances required (optimal)</TableData>
                    <TableData>{loading ? LoadingPoint : planData.requiredResources}</TableData>
                  </TableRow>
                ) : planData.extraResources ? (
                  <TableRow color={theme.color.allocationMonitor.warning}>
                    <TableData>Overflow instances</TableData>
                    <TableData>{loading ? LoadingPoint : planData.extraResources}</TableData>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableData>Instances required (optimal)</TableData>
                    <TableData>{loading ? LoadingPoint : planData.requiredResources}</TableData>
                  </TableRow>
                )}
                {
                  planData.teamsCount !== undefined && (
                    <TableRow>
                      <TableData>Teams count</TableData>
                      <TableData>{planData.teamsCount}</TableData>
                    </TableRow>
                  )
                }
                {
                  planData.individualPlayers !== undefined && (
                    <TableRow>
                      <TableData>Individuals count</TableData>
                      <TableData>{planData.individualPlayers}</TableData>
                    </TableRow>
                  )
                }
                <TableRow
                  color={getCurrentColor(
                    currentCount,
                    planData.lowerBound,
                    planData.optimal,
                    hasRun
                  )}
                >
                  <TableData>Current instances</TableData>
                  <TableData>{loading ? LoadingPoint : currentCount || 0}</TableData>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Table>
              <TableBody>
                <TableRow>
                  <TableData>Users waiting for resources</TableData>
                  <TableData>{loading ? LoadingPoint : planData.consumers}</TableData>
                </TableRow>
                <TableRow>
                  <TableData color={theme.color.allocationMonitor.optimal}>Average usage</TableData>
                  <TableData>{loading ? LoadingPoint : planData.averageUsage ? Number(Number(planData.averageUsage).toFixed(2)) : planData.averageUsage}</TableData>
                </TableRow>
                <TableRow color={theme.color.allocationMonitor.optimal}>
                  <TableData>Current count</TableData>
                  <TableData>{loading ? LoadingPoint : currentCount || 0}</TableData>
                </TableRow>
              </TableBody>
            </Table>
          )}
          <Flex ml="auto" mt="25px">
            <Flex width="100px" mr="6px">
              <Button onClick={onCancel} secondary>
                CANCEL
              </Button>
            </Flex>
            <Flex width="100px">
              <Button onClick={onRunClick} disabled={loading} danger={enableDeallocation}>
                {enableDeallocation ? 'DEALLOCATE' : 'RUN PLAN'}
              </Button>
            </Flex>
          </Flex>
        </Background>
      </SkeletonTheme>
    </Modal>
  );
}
