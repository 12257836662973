import { useEffect, useState } from 'react';
import Api from '../../api';

const API = new Api();

export const useGetResourceAllocationFeatureFlag = () => {
  // This is to retieve the feature flag configuration for the Resource Allocation feature
  const [loading, setLoading] = useState(true);
  const [flagConfig, setFlagConfig] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const result = await getResourceAllocationFlag();
      console.log(result);
      setFlagConfig(result);
      setLoading(false);
    }

    fetchData().then();
  }, []);
  return { loading, flagConfig };
};

export const getResourceAllocationFlag = async () => {
  const config = await API.get('events', `/admin/resource-allocation-flag`);
  return config;
}

export const useListAllEvents = () => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  useEffect(() => {
    async function fetchAllEvents() {
      let lastEvaluatedKey = {};
      const retrievedEvents = [];
      let paginationHasMore = true;
      while (paginationHasMore) {
        const result = await API.get('events', `/admin/events`, {
          queryStringParameters: {
            pageSize: 100,
            ...lastEvaluatedKey
          }
        });
        retrievedEvents.push(...result.events);
        paginationHasMore = result?.pagination?.more || false;
        lastEvaluatedKey = result.pagination.LastEvaluatedKey;
      }
      return retrievedEvents;
    }

    fetchAllEvents().then(eventsFromApi => {
      setEvents(eventsFromApi);
      setLoading(false);
    });
  }, []);
  return { loading, events };
};

export const submitFeatureFlagChanges = async (payload) => {
  return await API.post('events', `/admin/resource-allocation-flag`, {
    body: payload
  });
};