import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Api from '../../api';
import { onError } from '../../libs/error-lib';
import Resources from '../../components/Resources/Resources';
import { getResourceAllocationFlag } from '../FeatureFlags/ApiClient';


const API = new Api();
export default function ResourcesContainer() {
  const [resourcesSpec, setResourcesSpec] = useState([]);
  const [loadingItems, setLoadingItems] = useState(true);
  const [shouldUseAllocationV2, setShouldUseAllocationV2] = useState(false);
  const userRouteMatch = useRouteMatch('/events/:eventId/resources');
  const groupId = `srn:group::${userRouteMatch.params.eventId}`;

  const setAllocationFlag = async () => {
    const { eventId } = userRouteMatch.params;
    const config = await getResourceAllocationFlag();
    const isGloballyEnabled = config?.isGloballyEnabled?.enabled ?? false;
    let enabledForGroup = false;
    if (!isGloballyEnabled && config?.enabledGroupIds?.includes(eventId)) {
      enabledForGroup = true;
    }
    setShouldUseAllocationV2(isGloballyEnabled || enabledForGroup);
  }

  useEffect(() => {
    const getResources = async () => {
      setLoadingItems(true);
      try {
        await setAllocationFlag();
        const { specs } = await API.get('events', `/groups/${groupId}/specs`);
        setResourcesSpec(
          specs.map((spec) => ({
            ...spec,
            label: spec.title || spec.name,
            headerAction: true,
            value: spec.srn,
          }))
        );
        setLoadingItems(false);
      } catch (e) {
        onError(e);
      }
    };

    getResources();
    return () => API.abortCurrentRequest();
  }, [groupId]);

  return <Resources loadingResourceSpecs={loadingItems} resourcesSpec={resourcesSpec} shouldUseAllocationV2={shouldUseAllocationV2} />;
}
